import { coreStore } from "@forcepoint/platform-coreui"
import { useState } from "react"
import {
  showToastr,
  NeoComponents,
  NeoPopup,
  getAppUrl
} from "@forcepoint/platform-utilityui"

const ChangePasswordPopup = (props) => {
  /**
   * To store app messages
   */
  const appMessages = coreStore.getState()?.applicationMessages

  /**
   * To store invalid pass message
   */
  const invalidPassMsg = "{key} entered is incorrect"

  /**
   * To store blank password message
   */
  const blankPassMsg = "{key} cannot be blank"

  /**
   * To store same ass current password message
   */
  const sameAsCurrentPassMsg = "{key} cannot be same as current"

  /**
   * To store password doesn't match message
   */
  const passDoesntMatchMsg = "New Password & Confirm New Password doesn't match"

  /**
   * To store state data
   */
  const stateData = coreStore.getState()

  /**
   * To store user token
   */
  const userToken = stateData?.userToken

  /**
   * To store user id
   */
  const userId = stateData?.userInfo?.sub

  /**
   * To store validate password regex
   */
  const validatePassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-!@#$%&?<>=[\\]{}])[A-Za-z\\d -!@#$%&?<>=[\\]{}]{8,20}$"
  )

  /**
   * To store change password form initilization
   */
  const changePassFormInit = {
    currentPassword: { value: "", touched: false, text: "Current Password" },
    newPassword: { value: "", touched: false, text: "New Password" },
    confirmPassword: { value: "", touched: false, text: "Confirm New Password" }
  }

  /**
   * To store change password form errors initilization
   */
  const changePassFormErrorsInit = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  }

  /**
   * To store change password form
   */
  const [changePassForm, setChangePassForm] = useState<any>(changePassFormInit)

  /**
   * To store is valid form status
   */
  const [isValidForm, setIsValidForm] = useState<any>(false)

  /**
   * To store change password form errors
   */
  const [changePassFormErrors, setChangePassFormErrors] = useState<any>(
    changePassFormErrorsInit
  )

  /**
   * To store show password initial state
   */
  const showPassInitialState = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
    showConfirmPassword: false
  }

  /**
   * To store show password state
   */
  const [showPassState, toggleShowPass] = useState(showPassInitialState)

  /**
   * On click of show password
   * @param key
   */
  const onClickShowPass = (key) => {
    toggleShowPass({ ...showPassState, [key]: !showPassState[key] })
  }

  /**
   * Update details form
   */
  const updateDetails = (key, val) => {
    const formObj = { ...changePassForm, [key]: { value: val, touched: true } }
    let flag = true
    const tempFormErrors: any = {}
    if (
      formObj.currentPassword.value === formObj.newPassword.value &&
      formObj.currentPassword.value !== ""
    ) {
      flag = false
      tempFormErrors["newPassword"] = sameAsCurrentPassMsg.replace(
        "{key}",
        changePassFormInit["newPassword"].text
      )
    }
    for (const field in formObj) {
      if (
        !validatePassword.test(formObj[field].value) &&
        formObj[field].touched &&
        field != "confirmPassword"
      ) {
        flag = false
        tempFormErrors[field] = invalidPassMsg.replace(
          "{key}",
          changePassFormInit[field].text
        )
      }
      if (formObj[field].touched && formObj[field].value === "") {
        flag = false
        tempFormErrors[field] = blankPassMsg.replace(
          "{key}",
          changePassFormInit[field].text
        )
      }
    }

    if (
      !tempFormErrors?.confirmPassword &&
      formObj.confirmPassword.value != "" &&
      formObj.newPassword.value !== formObj.confirmPassword.value
    ) {
      tempFormErrors.confirmPassword = passDoesntMatchMsg
      flag = false
    }
    if (
      flag &&
      !(
        formObj.currentPassword.value &&
        formObj.newPassword.value &&
        formObj.confirmPassword.value
      )
    ) {
      flag = false
    }
    setIsValidForm(flag)
    setChangePassForm(formObj)
    setChangePassFormErrors(tempFormErrors)
  }

  /**
   * Update on touched form
   */
  const updateOnTouched = (key) => {
    const formObj = { ...changePassForm }
    let flag = true
    const tempFormErrors: any = {}
    formObj[key].touched = true
    const list = ["currentPassword", "newPassword", "confirmPassword"]
    if (
      formObj.currentPassword.value === formObj.newPassword.value &&
      formObj.currentPassword.value !== ""
    ) {
      flag = false
      tempFormErrors["newPassword"] = sameAsCurrentPassMsg.replace(
        "{key}",
        changePassFormInit["newPassword"].text
      )
    }
    for (const field of list) {
      if (
        !validatePassword.test(formObj[field].value) &&
        formObj[field].touched &&
        field != "confirmPassword"
      ) {
        flag = false
        tempFormErrors[field] = invalidPassMsg.replace(
          "{key}",
          changePassFormInit[field].text
        )
      }
      if (formObj[field].touched && formObj[field].value === "") {
        flag = false
        tempFormErrors[field] = blankPassMsg.replace(
          "{key}",
          changePassFormInit[field].text
        )
      }
    }
    if (
      !tempFormErrors?.confirmPassword &&
      formObj.confirmPassword.value != "" &&
      formObj.newPassword.value !== formObj.confirmPassword.value
    ) {
      tempFormErrors.confirmPassword = passDoesntMatchMsg
      flag = false
    }
    if (
      flag &&
      !(
        formObj.currentPassword.value &&
        formObj.newPassword.value &&
        formObj.confirmPassword.value
      )
    ) {
      flag = false
    }
    setIsValidForm(flag)
    setChangePassForm(formObj)
    setChangePassFormErrors(tempFormErrors)
  }

  /**
   * On Submit
   */
  const submit = () => {
    fetch(`${getAppUrl("idm")}api/users/${userId}/passwords`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userToken}`
      },
      body: JSON.stringify({
        oldPassword: changePassForm.currentPassword.value,
        newPassword: changePassForm.newPassword.value
      })
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res?.AdditionalInfo &&
          res.AdditionalInfo?.oldPassword &&
          res.AdditionalInfo.oldPassword === "Key_IncorrectOldPassword"
        ) {
          const toastConfig = {
            type: "error",
            message:
              appMessages.exception.change_password.Key_IncorrectOldPassword
          }
          showToastr(toastConfig)
          return
        } else if (
          res?.AdditionalInfo &&
          res.AdditionalInfo?.newPassword &&
          res.AdditionalInfo.newPassword === "Key_CannotBeSameAsOld"
        ) {
          const toastConfig = {
            type: "error",
            message: appMessages.exception.change_password.Key_CannotBeSameAsOld
          }
          showToastr(toastConfig)
          return
        }

        const toastConfig = {
          type: "success",
          message: appMessages.messages.change_password.SUCCESS_CHANGE_PASSWORD
        }
        showToastr(toastConfig)
        props.setChangePasswordPopupStatus(false)
      })
      .catch((err) => {
        const toastConfig = {
          type: "error",
          message: appMessages.messages.change_password.ERROR_CHANGE_PASSWORD
        }
        showToastr(toastConfig)
      })
  }
  return (
    <div className="popup-modal">
      <NeoPopup
        modalOpenStatus={props.changePasswordPopupStatus}
        modalTitle={"Change Password"}
        setOpenModalStatus={props.setChangePasswordPopupStatus}
        popupClass={"fone-modal-sm change-password-popup"}
      >
        <p className="password-modal-text">
          Try using multiple words to create a memorable phrase. Long passwords
          with many words are stronger than short passwords with special
          characters.
        </p>
        <div className="password-policy-info">
          <p> Password should follow these criteria's:</p>
          <ol>
            <li>Minimum 8 characters.</li>
            <li> Maximum 20 characters.</li>
            <li>At least one upper and one lower case letter.</li>
            <li>At least one digit.</li>
            <li>
              At least one special character from the following set. ! @ # $ % &
              ? = [ ] &lt; &gt; &#123; &#125;
            </li>
          </ol>
        </div>

        <div className="change-password">
          <div>
            <form style={{ paddingRight: "1rem" }} noValidate>
              <div style={{ width: "100%" }}>
                <div
                  style={{ marginBottom: "1rem", display: "flex" }}
                  className="mb-3 d-flex"
                >
                  <NeoComponents.NeoInput
                    {...{
                      id: "currentPassword",
                      placeholder: "Enter Current Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.currentPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "Current Password :*",
                      value: changePassForm?.currentPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.currentPassword,
                      onChange: (event) =>
                        updateDetails("currentPassword", event.value),
                      onBlur: (event) => updateOnTouched("currentPassword"),
                      autoComplete: "new-password",
                      autoFocus: true
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0px 10px" }}>
                      {!showPassState?.currentPassword ? (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="hidden-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("currentPassword")}
                          size={16}
                        />
                      ) : (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="show-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("currentPassword")}
                          size={16}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div style={{ 'width': '20px' }}></div> */}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <NeoComponents.NeoInput
                    {...{
                      id: "newPassword",
                      placeholder: "Enter New Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.newPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "New Password :*",
                      value: changePassForm?.newPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.newPassword,
                      onChange: (event) =>
                        updateDetails("newPassword", event.value),
                      onBlur: (event) => updateOnTouched("newPassword"),
                      autoComplete: "new-password"
                    }}
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ margin: "0px 10px" }}>
                      {!showPassState?.newPassword ? (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="hidden-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("newPassword")}
                          size={16}
                        />
                      ) : (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="show-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("newPassword")}
                          size={16}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <NeoComponents.NeoInput
                    {...{
                      id: "confirmPassword",
                      placeholder: "Confirm New Password",
                      containerStyle: { color: "#29343D" },
                      type: showPassState.confirmPassword ? "text" : "password",
                      maxLength: 20,
                      minLength: 4,
                      label: "Confirm New Password :*",
                      value: changePassForm?.confirmPassword.value,
                      showRequiredLabelIcon: true,
                      errorMessage: changePassFormErrors?.confirmPassword,
                      onChange: (event) =>
                        updateDetails("confirmPassword", event.value),
                      onBlur: (event) => updateOnTouched("confirmPassword"),
                      autoComplete: "new-password"
                    }}
                  />
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="info-tooltip"
                  >
                    <div style={{ margin: "0px 10px" }}>
                      {!showPassState?.confirmPassword ? (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="hidden-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("confirmPassword")}
                          size={16}
                        />
                      ) : (
                        <NeoComponents.NeoIcon
                          fill="#00af9a"
                          name="show-password-default"
                          data-always="true"
                          data-margin="2px"
                          data-padding="8px 12px"
                          data-white-space="nowrap"
                          data-word-break="normal"
                          onClick={() => onClickShowPass("confirmPassword")}
                          size={16}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div style={{ 'width': '20px' }}></div> */}
                </div>
              </div>
            </form>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem"
            }}
          >
            <NeoComponents.NeoSecondaryButton
              id="cp_cancel_btn"
              onClick={() => {
                props.setChangePasswordPopupStatus
                  ? props.setChangePasswordPopupStatus()
                  : null
              }}
            >
              Cancel
            </NeoComponents.NeoSecondaryButton>
            <span style={{ marginRight: "1rem" }}></span>
            <NeoComponents.NeoPrimaryButton
              id="cp_update_password_btn"
              isDisabled={!isValidForm}
              onClick={submit}
            >
              Update
            </NeoComponents.NeoPrimaryButton>
          </div>
        </div>
      </NeoPopup>
    </div>
  )
}
export default ChangePasswordPopup
